import React, { useState } from 'react';
import classNames from 'classnames';

import Button from '../Button';

import { ReactComponent as ArrowLeft } from '../../images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg';

import styles from './Carousel.module.css';

interface CarouselProps {
  carouselItems: {
    title: string;
    description: string;
    image?: React.ReactNode;
  }[];
}

function Carousel({ carouselItems }: CarouselProps) {
  const [selectedItem, setSelectedItem] = useState(1);
  const NbItems = carouselItems.length;
  const Multiplier = selectedItem - 1;

  function moveToNext() {
    if (selectedItem === NbItems) return setSelectedItem(1);
    setSelectedItem(selectedItem + 1);
  }

  function moveToPrevious() {
    if (selectedItem === 1) return setSelectedItem(NbItems);
    setSelectedItem(selectedItem - 1);
  }

  return (
    <div className={styles.Container}>
      <div className={styles.ButtonContainer}>
        <Button
          link
          notButtonStyle
          className={styles.Previous}
          onClick={moveToPrevious}
        >
          <ArrowLeft className={styles.Arrow} />
        </Button>
        <Button
          link
          notButtonStyle
          className={styles.Next}
          onClick={moveToNext}
        >
          <ArrowRight className={styles.Arrow} />
        </Button>
      </div>
      <div className={styles.ItemsContainer}>
        <div
          className={styles.InnerItemsContainer}
          style={{
            transform: `translate(calc((${-Multiplier} * ${
              (1 / NbItems) * 100
            }%)), 0%)`,
            gridTemplateColumns: `repeat(${NbItems}, 1fr)`
          }}
        >
          {carouselItems.map(({ image: Image, ...item }, index) => (
            <div
              key={item.title}
              className={classNames(styles.ItemCtn, {
                [styles.Selected]: index + 1 === selectedItem
              })}
            >
              <div className={styles.Details}>
                <h2 className={styles.ItemTitle}>{item.title}</h2>
                <p className={styles.ItemDescription}>{item.description}</p>
              </div>
              {Image && (
                <div className={styles.ImgCtn}>
                  <Image />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
