import React from 'react';
import classNames from 'classnames';

import Header from '../components/Header';
import Carousel from '../components/Carousel';
import Footer from '../components/Footer';
import Button from '../components/Button';

import { Name, BenefitsList, Plans } from '../utils/constant';

import { ReactComponent as SecurityShield } from '../images/icons/securityShield.svg';
import { ReactComponent as EuropeanUnion } from '../images/icons/europeanUnion.svg';
import { ReactComponent as From } from '../images/icons/forms.svg';
import { ReactComponent as Evaluation } from '../images/icons/evaluation.svg';
import { ReactComponent as Flow } from '../images/icons/flow.svg';
import { ReactComponent as GoLive } from '../images/icons/goLive.svg';
import { ReactComponent as Yes } from '../images/icons/positiveItem.svg';
import { ReactComponent as No } from '../images/icons/negativeItem.svg';

import styles from '../styles/index.module.css';

function IndexPage() {
  return (
    <main className={styles.Page}>
      <Header />

      <div className={styles.Content}>
        <div className={styles.Jumbotron}>
          <div className={styles.Wrapper}>
            <div className={styles.TitleCtn}>
              <h1 className={styles.Header}>Hire better.</h1>
              <h1 className={styles.Header}>Streamline your hiring process.</h1>
              <h1 className={styles.Header}>
                Hire collaboratively.{' '}
                <Button
                  notButtonStyle
                  link
                  to="/booking-demo"
                  className={styles.GetStartedLink}
                >
                  Get Started
                </Button>
              </h1>
            </div>
            <div className={styles.Benefits}>
              <div className={styles.BenefitCtn}>
                <p className={styles.BenefitItem}>
                  Impressive candidate experience
                </p>
              </div>
              <div className={styles.BenefitCtn}>
                <p className={styles.BenefitItem}>
                  Save time by automating your process
                </p>
              </div>
              <div className={styles.BenefitCtn}>
                <p className={styles.BenefitItem}>Select better candidates</p>
              </div>
            </div>
            <div className={styles.RectHeader} />
          </div>
        </div>
        <div className={styles.Block}>
          <div className={styles.Wrapper}>
            <h1 className={styles.Header}>How it works</h1>
            <div className={styles.BlockInnerContainerGrid}>
              <div className={styles.StepNumber1}>
                <div className={styles.IconCtn}>
                  <div className={styles.PreBlock} />
                  <div className={styles.MainBlock}>
                    <From className={styles.StepIcon} />
                  </div>
                  <div className={styles.PostBlock} />
                </div>

                <h2 className={styles.StepTitle}>1. Build Application Forms</h2>
              </div>
              <div className={styles.StepNumber2}>
                <div className={styles.IconCtn}>
                  <div className={styles.PreBlock} />
                  <div className={styles.MainBlock}>
                    <Evaluation className={styles.StepIcon} />
                  </div>
                  <div className={styles.PostBlock} />
                </div>
                <h2 className={styles.StepTitle}>2. Build Evaluation Forms</h2>
              </div>
              <div className={styles.StepNumber3}>
                <div className={styles.IconCtn}>
                  <div className={styles.PreBlock} />
                  <div className={styles.MainBlock}>
                    <Flow className={styles.StepIcon} />
                  </div>
                  <div className={styles.PostBlock} />
                </div>
                <h2 className={styles.StepTitle}>
                  3. Build your Review Process
                </h2>
              </div>
              <p className={styles.Description1}>
                User our powerful drag and drop form builder to create the ideal
                form for your needs. This include answer validation control,
                conditional rules (logic jumps), styling control and much more.
              </p>
              <p className={styles.Description2}>
                Use the same hyper-flexible form builder to design the perfect
                evaluation sheet to score your candidates and potential
                employees.
              </p>
              <p className={styles.Description3}>
                Build your review process. Choose the number of rounds, who will
                evaluate candidates in each round, and how.
              </p>
              <div className={styles.ConclusionProcess}>
                <div className={styles.StepNumber4}>
                  <div className={styles.IconCtn}>
                    <div className={styles.PreBlock} />
                    <div className={styles.MainBlock}>
                      <GoLive className={styles.StepIcon} />
                    </div>
                    <div className={styles.PostBlock} />
                  </div>

                  <h2 className={styles.StepTitle}>
                    <span className={styles.Go}>GO!</span>Your process is live.
                    Review candidates with your team.
                  </h2>
                  <p className={styles.Description4}>
                    Your candidates will have an amazing experience and will be
                    updated all along the process. All the while you and your
                    team and organized and efficient in your evaluation
                    selection process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.Block, styles.DarkBlock)}>
          <div className={styles.Wrapper}>
            <h1 className={styles.Header}>
              Your job posting and review process live in less than 2 hours!
            </h1>
            <div className={styles.BlockInnerContainer}>
              <h2 className={styles.SubHeader}>
                With our support at your side
              </h2>
              <ul className={styles.TextListUl}>
                <li className={styles.TextList}>
                  2 hours of training included to get you started asap!
                </li>
                <li className={styles.TextList}>
                  Ongoing live support to ensure you never miss a beat.
                </li>
                <li className={styles.TextList}>
                  Guided step-by-step onboarding process.
                </li>
                <li className={styles.TextList}>
                  Documentation so you have the choice to do it all on your own.
                </li>
                <li className={styles.TextList}>
                  Growing community of people who are ready to help and share.
                </li>
              </ul>
            </div>
            <div className={styles.RectDarkBlock} />
          </div>
        </div>
        <div className={styles.Block}>
          <div className={styles.Wrapper}>
            <h1 className={styles.Header}>Benefits</h1>
            <Carousel carouselItems={BenefitsList} />
          </div>
        </div>
        <div className={classNames(styles.Block, styles.LightBlock)}>
          <div className={styles.Wrapper}>
            <h1 className={styles.Header}>Safe and Compliant!</h1>
            <div className={styles.SafeInnerCtn}>
              <div className={styles.SafeCtn}>
                <div className={styles.SafeTopCtn}>
                  <h2 className={styles.SafeSubHeader}>Secure and Reliable</h2>
                  <SecurityShield />
                </div>
                <p className={styles.SafeExplanation}>
                  With our industry leading protocols, your data is safe and
                  secure.
                </p>
                <ul className={styles.SafeDescription}>
                  <li>
                    Triple redundancy servers to ensure high availability of the
                    service
                  </li>
                  <li>Data encrypted both in transit and at rest</li>
                  <li>Twice-daily back-up kept for 30 days</li>
                  <li>Database directly inaccessible from the internet</li>
                  <li>DDOS protection systems</li>
                  <li>
                    Daily check of access to ensure no breach passed through
                  </li>
                  <li>Disaster Recovery plan with DRO at 4 hours</li>
                </ul>
                <div className={styles.SafeRectangle} />
              </div>
              <div className={styles.SafeCtn}>
                <div className={styles.SafeTopCtn}>
                  <h2 className={styles.SafeSubHeader}>GDPR Compliant</h2>
                  <EuropeanUnion />
                </div>
                <p className={styles.SafeExplanation}>
                  At the heart of these rules is the ownership of your own data.
                  At {Name}, we are proud to provide you a fully GDPR compliant
                  solution.
                </p>
                <p className={styles.SafeDescription}>
                  We host your data securely and in compliance with GDPR, and
                  each of your candidates retains ownership over their own
                  information.
                </p>
                <p className={styles.SafeDescription}>
                  Their rights do not impede your ability to compile anonymized
                  information about previous applicants.
                </p>
                <p className={styles.SafeDescription}>
                  Securely hosted in Europe.
                </p>
                <div className={styles.CompliantRectangle} />
              </div>
            </div>
            <div className={styles.SafeCompRectangle} />
          </div>
        </div>
        <div className={styles.Block}>
          <div className={styles.Wrapper}>
            <h1 className={styles.Header}>Plans</h1>
            <div className={styles.PlansContainer}>
              {Plans.map(plan => (
                <div
                  key={plan.name}
                  className={classNames(styles.PlanCtn, {
                    [styles.RecommendedPlan]: plan.RecommendedPlan
                  })}
                >
                  <div className={styles.TopInformation}>
                    <h3 className={styles.PlanName}>{plan.name}</h3>
                    <p className={styles.PlanDescription}>{plan.subHeader}</p>
                  </div>
                  <div className={styles.PlanDetails}>
                    <React.Fragment>
                      {plan.includedItems.map(item => (
                        <div className={styles.Item} key={item}>
                          <Yes className={styles.Included} />
                          <p className={styles.Txt}>{item}</p>
                        </div>
                      ))}
                      {plan.notIncludedItems.length > 0 &&
                        plan.notIncludedItems.map(item => (
                          <div className={styles.Item} key={item}>
                            <No className={styles.NotIncluded} />
                            <p className={styles.Txt}>{item}</p>
                          </div>
                        ))}
                    </React.Fragment>
                  </div>
                  <div className={styles.Pricing}>
                    {plan.priceUSD !== null ? (
                      <React.Fragment>
                        <h2 className={styles.Price}>{plan.priceUSD}$</h2>
                        <p className={styles.PriceBasis}>per month per user</p>
                      </React.Fragment>
                    ) : (
                      <p>Call us!</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default IndexPage;
